<template>
    <div class="bg-tamayoz login">
        <FormContainer form-title="تأكيد الدخول" @onSubmit="login({ phone: user.phone, env })">
            <div class="form-group">
                <label>الاسم الكامل*</label>
                <input type="text" v-model="user.name" class="form-control" placeholder="الرجاء كتابة اسمك">
            </div>
            <div class="form-group">
                <label>رقم الهاتف المحمول*</label>
                <input @keyup.enter="login({ phone: user.phone, env })" type="number" v-model="user.phone"
                    class="form-control login-input" placeholder="الرجاء كتابة رقم هاتفك">
            </div>
        </FormContainer>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormContainer from '@/app/shared/components/forms/FormContainer';

export default {
    name: 'LoginPage',
    components: {
        FormContainer
    },
    data: () => ({ env: '' }),
    computed: {
        ...mapState('authStore', ['user'])
    },
    methods: {
        ...mapActions('authStore', ['login'])
    },
    beforeRouteEnter () {
        window.location.replace("https://rihab-alhabib.islamic-apps.com");
    },
    beforeCreate () {
        window.location.replace("https://rihab-alhabib.islamic-apps.com");
    },
    created () {
        this.env = this.$route.meta.from == 'atroja' ? 'dev' : 'prod'
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../../public/assets/styles/mixins";
.login {
    height: 101vh;
    margin-top: -5vh;

    @include respond(phone) {
        height: 104vh;
    }
}
</style>