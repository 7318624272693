<template>
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content box-shadow-z3">
            <div class="modal-header">
                <h5 class="modal-title">{{formTitle}}</h5>
            </div>
            <div class="modal-body p-lg">
                <form>
                    <slot />
                </form>
            </div>
            <div class="modal-footer">
                <button 
                    class="solid-button-one" 
                    :disabled="!isValidPhone"
                    @click="$emit('onSubmit')">
                    الدخول
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'FormContainerComponent',
    props: {
        formTitle: String
    },
    computed: {
        ...mapGetters('authStore', ['isValidPhone'])
    }
}
</script>

<style scoped>
.solid-button-one:disabled {
    background: transparent;
    border-color: #cbcbcb;
    color: #cbcbcb;
}
</style>